
	.wrap{
		min-height: 100%;
		background:url('../assets/image/sortBg.jpg') no-repeat;
		background-size: 100% 100%;
		color: #ffffff;
		padding: 0 0.4rem;
	}
	.top{
		text-align: center;
	}
	.top1{
		display: flex;
		align-items: center;
		padding-top: 0.4rem;
		font-size: 0.48rem;
	}
	.top1-left{
		flex-shrink: 0;
		text-align: left;
	}
	.left-number{
		margin-left: 0.2rem;
		font-size: 0.52rem;
	}
	.top1-right{
		flex: 1;
		text-align: left;
		padding-left: 1.6rem;
	}
	.top1-right span:first-child{
		color: #ff0000;
	}
	.top1-right span:nth-child(2){
		color:#faf801;
		font-weight: bold;
	}
	.top1-right span:last-child{
		font-size: 0.4rem;
	}
	.top2{
		font-size: 0.68rem;
		margin-top: 0.3rem;
	}
	.top3{
		margin-top: 0.16rem;
		font-size: 2.16rem;
		color: #ff0000;
		span{
			margin:0 0.6rem;
		}
	}
	.top4{
		display: flex;
		font-size: 0.44rem;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		span:first-child{
			margin-right: 0.1rem;
		}
		span:last-child{
			font-size: 0.72rem;
		}
	}
	.middle{
		margin-top: 0.2rem;
		div{
			padding:0 0.2rem;
			border:3px solid #e00101;
			text-shadow: 0 0.05rem 0.5rem #8df;
			color: #ffffff;
			font-size: 0.56rem;
			display: flex;
			align-items: center;
		}
	}
	.bottom{
		margin-top: 0.3rem;
	}
	.bottom1{
		display: flex;
		align-items:center;
		justify-content: space-between;
	}
	.bottom1-left{
		font-size: 0.48rem;
		flex: 1;
	}
	.bottom1-left span:first-child{
		color: #ff0000;
	}
	.bottom1-left span:nth-child(2){
		color:#faf801;
		font-weight: bold;
	}
	.bottom1-left span:last-child{
		font-size: 0.4rem;
	}
	.bottom1-right span:first-child{
		font-size: 0.44rem;	
		margin-right: 0.1rem;
	}
	.bottom1-right span:last-child{
		font-size: 0.52rem;
	}
	.bottom2{
		display: flex;
		font-size: 0.44rem;
		margin-top: 0.35rem;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		span:first-child{
			margin-right: 0.1rem;
		}
		span:last-child{
			font-size: 0.72rem;
		}
	}
	.surplus2{
		width: 30%;
	}
	.surplus2.on{
		width: 50%;
	}
	.bottom3{
		font-size:0.28rem;
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		margin-top: 0.06rem;
	}
	.bottom4{
		padding:0 0.2rem;
		position: absolute;
		bottom:10px;
		left:0;
		width: 100%;
		display: flex;
		font-size:20px;
		align-items: center;
		span{
			width: 33.3%;
		}
	}
	
	// 扫码购票
	.scan-code{
		margin-top: 2.8rem;
	}
	.scan-img{
		text-align: center;
		img{
			width:200px;
		}
	}
	.scan-introduce{
		padding: 1.2rem 0 0 1.6rem;
	}
	.scan-phone div{
		margin-top: 0.6rem;
		font-size: 0.4rem;
	}
	@media only screen and (max-height:1000px) {
	  .bottom4{
			position: initial;
			margin-top: 0.2rem;
			padding: 0;
			padding-bottom: 10px;
		}
		.bottom4 span:last-child{
			position: relative;
			left: 0.2rem;
		}
	}
	
