body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    margin: 0;
    padding: 0;
	box-sizing: border-box;
}
table{border-collapse:collapse;border-spacing:0;text-align:left}
input,textarea,button,select,a{outline:0 none;border: none;background: none;}
img,object,input,textarea,button,select{vertical-align:middle}
html {height: 100%;font-size: 50px;}
body {height: 100%;color: #333;font-size: 0.16rem;}
img {border: none;}
a {text-decoration: none;}
i{font-style: normal;}
/*单行溢出*/
.one-txt-cut{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}