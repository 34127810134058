
	.wrap{
		min-height: 100%;
		background:url('../assets/image/suodao.jpg') no-repeat;
		font-size: 14px;
		padding: 4vh 12px;
		background-size: 100% 100%;
	}
	.time{
		background: #ffffff;
		border-radius: 10px;
		padding: 15px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.area{
		margin-top: 3vh;
		background: #ffffff;
		border-radius: 10px;
		padding: 20px;
	}
	.area-address{
		font-weight: bold;
		span{
			color: #ff0000;
			font-size: 16px;
		}
	}
	.area-number{
		font-weight: bold;
		font-size: 16px;
		text-align: center;
		margin-top: 4vh;
		color: #ff0000;
		p:last-child{
			font-size: 30px;
		}
	}
	.area-time{
		margin-top: 4vh;
	}
	.tips{
		margin-top: 3vh;
		line-height: 28px;
		color: #ffffff;
	}
