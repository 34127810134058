
  .capacity-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
    background-color: #2c445a;
    color: #fff;
    font-size: 0.48rem;
    head {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      height: 35%;
      border-bottom: 1px solid #fff;
      h3 {
        letter-spacing: 20px;
      }
      p {
        font-size: 0.32rem;
      }
    }
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.36rem;
    }
    .item {
      margin-bottom: 0.24rem;
    }
  }
  .not-config {
    color: red;
  }
