
	#app{
		height: 100%;
	}
	.tip-wrapper {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background:rgba(0, 0, 0, 0.5);
	  z-index: 99999;
	  .tip-box {
	    width:30%;
	    padding: 0.4rem;
	    background: #fff;
	    border-radius: 4px;
	    color:#ff0000;
	    font-size: 0.32rem;
	  }
	}
