
	.wrap{
		background:url('../assets/image/picture.jpg') no-repeat;
		font-size: 16px;
		height:100%;
		background-size:100% 100%;
	}
	.title{
		font-size: 20px;
		font-weight: bold;
		padding-top: 10vh;
		text-align: center;
		color: #fff;
	}
	.content{
		width: 70%;
		border-radius: 10px;
		background: #fff;
		padding: 30px 0;
		margin: 5vh auto 0 auto;
		text-align: center;
	}
	.number{
		margin-top: 6vh;
		color: #0f5efe;
		p:last-child{
			font-size: 32px;
			font-weight: bold;
			span{
				font-size: 12px;
			}
		}
	}
