
.camera-data-screen {
  position: relative;
  max-width: 480px;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 16px 16px 16px;
  color: #fff;
  background: url("@/assets/image/phoneBg.png") 0 0/100% repeat-y;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 36px 0;

  .company-box {
    text-align: center;
    color: #fff;
    font-size: 14px;
    margin-bottom: 44px;
    .company {
      display: block;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .date {
      display: block;
      position: relative;
      &::after {
        content: '';
        width: 90%;
        height: 4px;
        position: absolute;
        left: 2px;
        bottom: -6px;
        background: url('@/assets/image/dateBg.png') no-repeat;
      }
    }
  }

  .btn {
    position: relative;
    width: 104px;
    height: 41px;
    font-size: 18px;
    color: #4659ce;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url("@/assets/image/btn.png") no-repeat;
      background-size: 100% 100%;
    }

    &.active {
      color: #fff;

      &::before {
        background: url("@/assets/image/activeBtn.png") no-repeat;
        transform: rotateY(0);
      }

      &.live-broadcast::before {
        transform: rotateY(180deg);
      }
    }
  }

  .passenger-flow {
    margin-right: 4px;

    &::before {
      transform: rotateY(180deg);
    }
  }
}

.text {
  position: relative;
}

.group-name {
  font-size: 16px;
}



.first-group {
  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .group-name {
    position: relative;
    margin-bottom: 16px;

    &::before {
      content: "";
      position: absolute;
      width: 32px;
      height: 8px;
      left: -1px;
      bottom: -2px;
      background: url("@/assets/image/decoration.png") no-repeat;
    }
  }
}

.small-font {
  font-size: 12px;
  color: #ccc;
  margin-left: 12px;
}

.capacity {
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 16px;
}

.divider {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #757b91;
  margin: 24px 0;
}

.bottom-box {
  display: flex;
  justify-content: space-between;

  .inner-item {
    width: 48%;
    height: 102px;
    border-radius: 16px;
    background-color: #ffffff30;
    backdrop-filter: blur(30px);
    padding: 12px;
    box-sizing: border-box;

    .classify {
      font-size: 16px;
    }
  }

  .icon-info {
    margin-top: 16px;

    img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }

    .count {
      position: relative;
      top: 6px;
      font-size: 24px;
    }
  }
}

.other-group {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 48%;
    height: 177px;
    background: #4659ce;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px 8px 8px 8px;
    padding: 12px 8px 16px;
    margin-bottom: 4%;

    .capacity {
      font-size: 40px;
      margin-bottom: 0;
    }
  }

  .item:nth-child(2n-1) {
    margin-right: 4%;
  }

  .inner-item {
    width: 48%;
    height: auto;
    padding: 4px;
    border-radius: 8px;

    .classify {
      font-size: 10px;
    }

    .icon-info {
      margin-top: 8px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .count {
      font-size: 14px;
    }
  }

  .inner-item:nth-child(2n-1) {
    margin-right: 4px;
  }
}

.current-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #4659ce;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 0 16px 0 12px;

  .capacity {
    font-size: 36px;
    margin-bottom: 0;
  }
}

.not-config {
  font-size: 24px;
  color: #fff;
  letter-spacing: 4px;
}

.video-box {
  position: relative;
  & >>> .sub-wnd {
    margin-bottom: 16px !important;
  }
  .handle-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .item {
    position: relative;
    width: 100%;
    flex: 1;
    margin-bottom: 16px;
    .top-box {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 6px;
      color: #ccc;
      font-size: 16px;
      z-index: 99;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & > div {
      width: 100%;
      height: 250px;
    }

    .start,
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: rgba($color: #fff, $alpha: 0.5);
      color: #fff;
    }

    .start {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-20%, -50%);
        content: "";
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-left-color: #fff;
      }
    }

    .loading {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-125%, -50%);
        content: "";
        width: 4px;
        height: 12px;
        background: #fff;
        border-radius: 2px;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(50%, -50%);
        content: "";
        width: 4px;
        height: 12px;
        background: #fff;
        border-radius: 2px;
      }
    }

    .camera-name {
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      font-size: 14px;
      color: #fff;
      margin-top: 6px;
    }
  }
}
