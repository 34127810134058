
	
	.wrap{
	  background: rgba(0, 0, 0, 0.3);
	  position: absolute;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  font-size:0.16rem;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  z-index: 99999;
	}
	.wrap pre {
	  white-space: pre-wrap;
	  word-wrap: break-word;
	  line-height: 0.2rem;
		margin: 0.2rem 0;
	}
	.wrap-content {
	  width: 70%;
	  height: 80%;
	  background: #ffffff;
	}
	.top {
	  border-bottom: 0.1rem solid #ececec;
	  padding: 0.1rem 0.15rem;
	  height: 8%;
	  display: flex;
	  align-items: center;
	}
	.top span{
	  margin-left:0.05rem;
	  color: #ff0000;
		font-size:0.2rem;
		font-weight: bold;
	}
	.middle {
	  padding:0.1rem 0.15rem;
	  border-bottom:0.1rem solid #ececec;
	}
	.middle-content{
		margin-top:0.1rem;
		display: flex;
		align-items: center;
	}
	#pro{
		width:4rem;
		height: 0.4rem;
	}
	.bottom{
	  height: 75%;
	  overflow-y: scroll;
	}
	.bottom-list{
		margin-top: 0.3rem;
		border-bottom: 2px solid #ececec;
		padding: 0 0.24rem;
	}
	.bottom-list div:not(:first-child){
		margin-top: 0.15rem;
	}
	.bottom-list span{
		margin-left: 0.1rem;
	}
	.logining{
		text-align: center;
		margin-top:20%;
		font-size: 0.3rem;
		color: #ff0000;
	}
	.error{
		display: flex;
		height: 0.6rem;
		align-items: center;
		justify-content: space-between;
		font-size: 0.3rem;
		color: #ff0000;
		padding: 0 0.3rem;
		position: absolute;
		bottom: 0;
		width: 100%;
	}
